<template>
    <div class="stage-inner-container-padded">
        <h2>404 - Page Not Found</h2>
    </div>
</template>

<script>
const butils = require('../libs/basicUtils.js');

export default {
    name: '404',
    components:{

    },
    props:{

    },
    data(){
        return{

        }
    },
    methods:{

    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){

    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>